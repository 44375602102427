import {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {AppContext} from '../../../context/AppContext';

import useWindowSize from '../../../hooks/useWindowSize';
import useAxios from 'axios-hooks';
import {API_URL, API_NAME} from '../../../config/config.json';

import './Header.scss';

export function Header() {
  const {setModalIsOpened, currentPath} = useContext(AppContext);

  const [isCollapsed, setIsCollapsed] = useState('');
  const [mobileNav, setMobileNav] = useState(false);
  const {width} = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);
  const [{data, loading}] = useAxios(`${API_URL}/${API_NAME}/getNavigation`);

  useEffect(() => {
    width < 811 ? setIsMobile(true) : setIsMobile(false);
  }, [width]);

  useEffect(() => {
    window.onscroll = function (i: any) {
      const currentScrollPos = window.pageYOffset;
      currentScrollPos === 0 ? setIsCollapsed('') : setIsCollapsed('is-collapsed');
    };
  }, []);

  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        if (window.innerWidth > 810) {
          setMobileNav(false);
        }
      },
      false,
    );
  }, []);

  useEffect(() => {
    if (document.body.classList.contains('page-exit')) {
      setMobileNav(false);
    }
  }, []);

  useEffect(() => {
    mobileNav ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'auto');
  }, [mobileNav]);

  var navElements = document.getElementsByClassName('nav-link');
  for (var i = 0; i < navElements.length; i++) {
    navElements[i].addEventListener(
      'click',
      () => {
        setMobileNav(false);
        setModalIsOpened(false);
      },
      false,
    );
  }

  return (
    <header
      className={`${isCollapsed} ${mobileNav ? 'mobileIsOpened' : ''} ${currentPath === '/about' ? 'about-page' : ''} `}
    >
      <div className="inner row">
        <div className="brand">
          <Link
            to={`/`}
            className="nav-link"
            onClick={() => {
              setModalIsOpened(false);
              window.scrollTo(0, 0);
            }}
            title="Andia Angelidou"
          >
            <h1>Andia Angelidou</h1>
          </Link>
        </div>
        {!loading && data && !isMobile && (
          <nav>
            {currentPath === '/' && (
              <Link to={`/about`} title="About" className="nav-link">
                About
              </Link>
            )}
            {currentPath === '/about' && (
              <Link to={`/`} title="Selected Word" className="nav-link">
                Selected Work
              </Link>
            )}
          </nav>
        )}
        {isMobile && (
          <>
            <div className="mobileNav" onClick={() => setMobileNav(!mobileNav)}>
              <div className={`mobileOpen`}></div>
            </div>
            <div className="mobileMenu">
              <div className="brand">
                <Link to={`/`} className="nav-link">
                  Andia Angelidou
                </Link>
              </div>
              <div className="pageNav">
                <div className="navItems">
                  <div className="item">
                    <Link className="nav-link" to={`/about`}>
                      About
                    </Link>
                  </div>
                  <div className="item">
                    <Link className="nav-link" to={`/`}>
                      Selected Work
                    </Link>
                  </div>
                  <div className="item">
                    <div
                      onClick={() => {
                        setModalIsOpened(true);
                        setMobileNav(false);
                      }}
                    >
                      Contact
                    </div>
                  </div>
                </div>
                <div className="socialNav">
                  <div className="item">
                    <a
                      href="https://www.behance.net/a_andia"
                      title="behance"
                      className="nav-link"
                      rel="noreferrer"
                      target="_blank"
                    >
                      behance
                    </a>
                  </div>
                  <div className="item">
                    <a
                      href="https://www.instagram.com/andia_angelidou"
                      title="instagram"
                      className="nav-link"
                      rel="noreferrer"
                      target="_blank"
                    >
                      instagram
                    </a>
                  </div>
                  <div className="item">
                    <a
                      href="https://www.linkedin.com/in/andiaangelidou/"
                      title="linkedin"
                      className="nav-link"
                      rel="noreferrer"
                      target="_blank"
                    >
                      linkedin
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </header>
  );
}
