import React, {useContext, useEffect, useState} from 'react';
import lottie from 'lottie-web';
import useWindowSize from '../../../hooks/useWindowSize';
import {AppContext} from '../../../context/AppContext';
import Data from '../../../assets/data-lets-work.json';

import './Footer.scss';

export function Footer() {
  const {currentScroll, currentPath, setModalIsOpened, hideFooter, setHideFooter, currentPosition} =
    useContext(AppContext);
  const mainDiv = document.querySelector('body')?.offsetHeight as number;
  const {height} = useWindowSize();
  useEffect(() => {
    const container = document.getElementsByClassName('svgContainer')[0];
    if (container) {
      lottie.loadAnimation({
        container: container,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: Data,
      });
    }
  }, []);
  // useEffect(() => {
  //   window.onscroll = () => {
  //     setScroll(window.pageYOffset);
  //     if (window.pageYOffset === 0) {
  //       setHideFooter(false);
  //     } else {
  //       setHideFooter(true);
  //     }
  //     console.log(scroll);
  //   };
  // }, [scroll, setHideFooter, setScroll]);
  const jumpToTop = () => {
    const test = document.getElementsByClassName('swiper-pagination-bullet')[0] as HTMLElement;
    if (test) test.click();
  };
  return (
    <footer>
      <div className={`row inner `}>
        {/* {mainDiv &&
          currentScroll !== '0' &&
          window.pageYOffset > mainDiv - (window.innerHeight + 100) &&
          currentPath === '/about' && (
            <div
              className="back"
              onClick={() => {
                window.scrollTo(0, 0);
                setHideFooter(false);
              }}
            >
              top
            </div>
          )} */}
        <div className="footer-items about">
          <div
            className={`socialNav`}
            style={{opacity: hideFooter || (currentPosition === 'Bot' && currentPath === '/about') ? '0' : '1'}}
          >
            <div className="item">
              <a
                href="https://www.behance.net/a_andia"
                title="behance"
                className="nav-link"
                rel="noreferrer"
                target="_blank"
              >
                behance
              </a>
            </div>
            <div className="item">
              <a
                href="https://www.instagram.com/andia_angelidou"
                title="instagram"
                className="nav-link"
                rel="noreferrer"
                target="_blank"
              >
                instagram
              </a>
            </div>
            <div className="item">
              <a
                href="https://www.linkedin.com/in/andiaangelidou/"
                title="linkedin"
                className="nav-link"
                rel="noreferrer"
                target="_blank"
              >
                linkedin
              </a>
            </div>
          </div>
        </div>
        {currentPosition !== 'Mid' && (
          <div
            className={`footer-items ${currentPosition === 'Bot' ? 'back' : ''} ${
              currentPosition === 'Top' ? 'more' : ''
            }`}
            title={currentPosition === 'Top' ? 'scroll down to navigate' : 'back to top'}
            style={{
              transform: `translateX(-50%) ${currentScroll !== '0' ? 'translateY(200px)' : 'translateY(0)'}`,
              opacity: currentScroll !== '0' ? 0 : 1,
              position: 'absolute',
              left: '50%',
            }}
            onClick={() => jumpToTop()}
          >
            {currentPosition === 'Top' && <>{currentPath === '/' ? 'more selected work' : 'more'}</>}
            {currentPosition === 'Bot' && <>top</>}
          </div>
        )}
        <div
          className="footer-items contact"
          onClick={() => setModalIsOpened(true)}
          style={{opacity: hideFooter || (currentPosition === 'Bot' && currentPath === '/about') ? '0' : '1'}}
        >
          <div className="svgContainer"></div>
        </div>
      </div>
    </footer>
  );
}
