import './Main.scss';

interface MainLayoutProps {
  children: any;
}

export const Main = (props: MainLayoutProps) => {
  const {children} = props;
  // const [{ data }] = useAxios(
  //   `${API_URL}/${API_NAME}/${get_singletons}/ssettings?rspc=1`
  // );

  // if (data) {
  //   localStorage.setItem('settings', JSON.stringify(data));
  // }

  return <main>{children}</main>;
};
