import { Header } from "../Header";
import { Main } from "../Main";
import { Footer } from "../Footer";
import { Modal } from "../../elements/Modal";
import "./Container.scss";

interface IContainerProps {
  children: any;
}

export function Container(props: IContainerProps) {
  const { children } = props;

  return (
    <div className={`layout`}>
      <Header />
      <Main>{children}</Main>
      <Footer />
      <Modal />      
    </div>
  );
}
