import React, {Suspense} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {lazyImport} from '../utils/lazyImport';
import {Container} from '../components/layout/Container';
import {Loader} from '../components/elements/Loader';
import CacheBuster from 'react-cache-buster';
import {version} from '../../package.json';
import {isProduction, basename} from '../config/config.json';
import {ReactComponent as UISvg} from '../assets/ui/ui.svg';
import ScrollToTop from '../utils/scrollToTop';
import {AppContextProvider} from '../context/AppContext';

// const {Home} = lazyImport(() => import('./Home'), 'Home');
const {Home} = lazyImport(() => import('./Home'), 'Home');
const {About} = lazyImport(() => import('./About'), 'About');

function App() {
  return (
    <CacheBuster currentVersion={version} isEnabled={isProduction} isVerboseMode={false} loadingComponent={<Loader />}>
      <BrowserRouter basename={basename}>
        <Suspense fallback={<Loader />}>
          <AppContextProvider>
            <Container>
              <Switch>
                <Route exact path={`/`} component={Home} />
                <Route exact path={`/about`} component={About} />
              </Switch>
            </Container>
          </AppContextProvider>
        </Suspense>
        <UISvg />
        <ScrollToTop />
      </BrowserRouter>
    </CacheBuster>
  );
}
export default App;
