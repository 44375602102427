import React, { useContext, useEffect } from "react";
import { AppContext } from "../../../context/AppContext";
import lottie from 'lottie-web'
import Data from '../../../assets/data-dedicated.json'

import "./Modal.scss";

export function Modal() {
  const { modalIsOpened, setModalIsOpened } = useContext(AppContext);

  useEffect(() => {
    const container = document.getElementsByClassName('svgContainer-dedicated')[0];
    if (container) {
      lottie.loadAnimation({
      container: container,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: Data,
    })
  }
  }, []);
  
  document.onkeydown = function(evt) {
    evt = evt || window.event;
    let isEscape = false;
    if ("key" in evt) {
        isEscape = (evt.key === "Escape" || evt.key === "Esc");
    } else {
        isEscape = (evt.keyCode === 27);
    }
    if (isEscape) {
      setModalIsOpened(false)
    }
};
  return (
    <div className={`modal ${modalIsOpened === true ? "isOpened" : ""}`}>
      <div className="modalInner">
        <div className="top">
          <div className="title">YOU ARE WELCOME TO REACH ME AT:</div>
          <div className="svgContainer-dedicated"></div>
          <div className="email"><a href="mailto:more@andia-angelidou.gr">more@andia-angelidou.gr</a></div>
        </div>
        <div className="back" onClick={() => setModalIsOpened(false)}>back</div>
      </div>
    </div>
  );
}
