import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router';

export interface AppContextProps {
  currentScroll: string;
  setCurrentScroll: (currentScroll: string) => void;
  currentPath: string;
  setCurrentPath: (currentPath: string) => void;
  modalIsOpened: boolean;
  setModalIsOpened: (isOpened: boolean) => void;
  hideFooter: boolean;
  setHideFooter: (isFooterHidden: boolean) => void;
  currentPosition: string;
  setCurrentPosition: (currentPotition: string) => void;
}

interface AppContextProviderProps {
  children: any;
}

export const AppContext = React.createContext<AppContextProps>({
  currentScroll: '0',
  setCurrentScroll: () => null,
  currentPath: '/',
  setCurrentPath: () => null,
  modalIsOpened: false,
  setModalIsOpened: () => null,
  hideFooter: false,
  setHideFooter: () => null,
  currentPosition: 'Top',
  setCurrentPosition: () => null,
});

export const AppContextProvider: React.FC<AppContextProviderProps> = ({children}) => {
  const [currentScroll, setCurrentScroll] = useState('0');
  const [currentPath, setCurrentPath] = useState('/');
  const [currentPosition, setCurrentPosition] = useState('Top');
  const [modalIsOpened, setModalIsOpened] = useState(false);
  const [hideFooter, setHideFooter] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [setCurrentPath, location]);

  useEffect(() => {
    if (location.pathname === '/') {
      setHideFooter(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    window.onscroll = function (e: any) {
      setCurrentScroll(`${window.pageYOffset}`);
    };
  }, [setCurrentScroll]);

  useEffect(() => {
    modalIsOpened
      ? (document.getElementsByTagName('html')[0].style.overflowY = 'hidden')
      : (document.getElementsByTagName('html')[0].style.overflowY = 'scroll');
  }, [modalIsOpened]);

  return (
    <AppContext.Provider
      value={{
        currentScroll,
        setCurrentScroll,
        currentPath,
        setCurrentPath,
        modalIsOpened,
        setModalIsOpened,
        hideFooter,
        setHideFooter,
        currentPosition,
        setCurrentPosition,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppContext.displayName = 'AppContext';
